import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const SubServiceDetail = () => {
  const { id } = useParams();
  const [subService, setSubService] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the sub service detail data from the API
    fetch(`https://admin.auun.net/api/show_sub_service/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json', // Ensure correct content type
        'lang': 'ar' // Assuming the API expects the 'lang' header for Arabic content
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.status && data.data) {
          setSubService(data.data); // Assuming data is an object containing the sub-service details
        } else {
          throw new Error(data.message || 'Invalid data structure');
        }
      })
      .catch(error => {
        console.error('Error fetching sub service detail:', error);
        setError('The requested service could not be found or the endpoint is incorrect.');
      });
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!subService) {
    return <div>Loading...</div>;
  }

  return (
    <div className="governance-container">
    <div class="financial-consultation">
    
    <div class="content-container">
    <h2 className="service-title">{subService.title}</h2>
    <p className="" dangerouslySetInnerHTML={{ __html: subService.description }}></p>
    </div>
    <div class="image-containers">
    <img src={`https://admin.auun.net/${subService.image}`} alt={subService.title} />
    </div>
</div>


      <div className="container ">
        <h2 className='text-center title'>أرسل رسائلك عبر هذا النموذج</h2>
        <div className="contact mb-5">
          <div className="form-container">
            <form action="#" method="post">
              <div className="d-flex">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">الاسم</label>
                    <div className="input-group">
                      <input type="text" id="name" name="name" placeholder="الأسم" required />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email">البريد الإلكتروني</label>
                    <div className="input-group">
                      <input type="email" id="email" name="email" placeholder="البريد الإلكتروني" required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone">رقم الجوال</label>
                    <div className="input-group">
                      <input type="phone" id="phone" name="phone" placeholder="رقم الجوال" required />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="messageType">اسم الشركة </label>
                    <input type="text" placeholder='اسم الشركة' />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">الرسالة</label>
                <textarea id="message" rows={5} name="message" placeholder="اكتب هنا"></textarea>
              </div>
              <button type="submit">إرسال</button>
            </form>
          </div>
        </div>
      </div>
      
    </div>
  );
};
export default SubServiceDetail;
