import React, { useState, useEffect } from 'react';
import './Blogger.css';
import group from "../assest/8.png";
import circle from "../assest/Ellipse 1.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Card from '../Cards/CardContainer';
import { Link } from 'react-router-dom'; // Import Link

const Blogger = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch categories data from the API
    fetch('https://admin.auun.net/api/categories')
      .then(response => response.json())
      .then(data => {
        if (data.status && data.data) {
          setCategories(data.data);
        } else {
          throw new Error('Failed to load categories');
        }
      })
      .catch(error => {
        console.error('Error fetching the categories data:', error);
        setError(error.message);
      });

    // Fetch blogs based on category selection
    const endpoint = activeCategoryId !== null ? 'https://admin.auun.net/api/cat_blogs' : 'https://admin.auun.net/api/all_blog';
    fetch(endpoint, {
      method: activeCategoryId !== null ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        'lang':'ar'
      },
      body: activeCategoryId !== null ? JSON.stringify({ cat_id: activeCategoryId }) : undefined,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status && data.data) {
          setBlogs(data.data);
        }
      })
      .catch(error => console.error('Error fetching the blog data:', error));
  }, [activeCategoryId]);

  const handleCategoryClick = (catId) => {
    // Toggle the active category between the selected category and null
    if (activeCategoryId === catId) {
      setActiveCategoryId(null);
    } else {
      setActiveCategoryId(catId);
    }
  };

  const getTextWithLimitedLines = (text, maxLines = 2) => {
    const lines = text.split('\n');
    if (lines.length <= maxLines) {
      return text;
    }
    return lines.slice(0, maxLines).join('\n') + '...';
  };
  return (
    <>
      <div className="background blogger">
        <img src={group} alt="gif" />
        <div className="overlayy-text">
          <h1 style={{ textAlign: 'center' }}>مدونة عون المتميزة</h1>
      
            <div style={{ textAlign: 'center' }} className="input-with-icon">
              <input
                className=" "
                type="search"
                placeholder="ابحث"
                aria-label="Search"
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-purple-600 pointer-events-none"
              />
            </div>
            <ul className="bloggerNav ">
              {error ? (
                <li className="nav-item">
                  <a className="nav-link disabled" href="#">Error loading categories</a>
                </li>
              ) : categories.length > 0 ? (
                categories.map((category) => (
                  <li className="nav-item" key={category.id}>
                    <a style={{
                            border: '1px solid #311356',
                            borderRadius: '15px',
                            fontSize: '18px',
                            padding: '5px 10px',
                            textDecoration: 'none',
                            color: '#311356',
                            display: 'inline-block'
                        }}
                      className={`nav-link ${activeCategoryId === category.id ? 'active' : ''}`}
                      href="#"
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      {category.name}
                    </a>
                  </li>
                ))
              ) : (
                <li className="nav-item">
                  <a className="nav-link disabled" href="#">Loading categories...</a>
                </li>
              )}
            </ul>
          </div>
        </div>

      <div className="container cardContainer">
        <div className="row">
          {blogs.length > 0 ? (
            blogs.map((blog) => (
              <div className="col-md-6 cardGap" key={blog.id}>
                 <Link to={`/blog/${blog.id}`}>
                <Card
                  imgSrc={`https://admin.auun.net${blog.image}`}
                  circleSrc={circle}
                  title={blog.title}
                  date={new Date().toLocaleDateString()} // You might want to use the actual date if available
                  text={blog.description.replace(/<[^>]+>/g, '').substring(0, 100)} // Stripping HTML tags from description
                />
                </Link>
              </div>
            ))
          ) : (
            <p>No blogs available for this category.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Blogger;
