import React, { useEffect, useState } from 'react';
import background from '../assest/background.png';
import overlay from '../assest/objects.png';
import vector from '../assest/profile avatar.png';
import phone from '../assest/3.png';
import mail from '../assest/2.png';
import location from '../assest/1.png';

const OfferPrice = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company_name: '',
        messageType: '',
        message: '',
        service_id:''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send form data to the API
        fetch('https://admin.auun.net/api/add_priceoffer', {
            method: 'POST',
            body: new FormData(e.target) // Automatically appends all form fields
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    alert('تم إرسال طلب عرض السعر بنجاح!');
                } else {
                    alert('حدث خطأ أثناء إرسال طلب عرض السعر.');
                }
            })
            .catch(error => {
                console.error('Error sending price offer:', error);
                alert('حدث خطأ أثناء إرسال طلب عرض السعر.');
            });
    };

    return (
        <>
            <div className="contact">
                <div className='ContactUs'>
                    <div className='image-container'>
                        <img src={background} alt="Background" />
                        <div className='overlay'>
                            <img src={overlay} alt="Overlay" />
                        </div>
                        <div className='titles'>
                            <h1>أحصل على استشارة مجانية</h1>
                            <p>تواصل معنا من خلال النموذج أو عبر وسائل التواصل الظاهرة نحن بخدمتكم</p>
                        </div>
                    </div>
                </div>
                <div className="container mb-5">
                    <h1 className='text-center title'>أرسل طلبك عبر هذا النموذج</h1>
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">الاسم</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="الاسم"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="email">البريد الإلكتروني</label>
                                        <div className="input-group">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="البريد الإلكتروني"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="phone">رقم الجوال</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                placeholder="رقم الجوال"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="company_name">اسم الشركة</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            id="company_name"
                                            name="company_name"
                                            placeholder="اسم الشركة"
                                            value={formData.company_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="messageType">الخدمات</label>
                                <select 
                                    id="service_id" 
                                    name="service_id" 
                                    value={formData.service_id} 
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">اختر خدمة</option>
                                    <option value="1">الإستشارات المالية</option>
                                    <option value="2">الإستشارات الإدارية</option>
                                    <option value="3">خدمات المستثمرين</option>
                                    <option value="4">الحلول التقنية والبرامج المحاسبية</option>
                                    <option value="5">تطوير الاعمال والتخطيط الاستراتيجي</option>
                                    <option value="6">خدمات مع شركائنا</option>
                                    <option value="7">الحوكمة</option>
                                </select>
                            </div>
                  
                            <div className="form-group">
                                <label htmlFor="message">الرسالة</label>
                                <textarea
                                    id="message"
                                    rows={5}
                                    name="mes"
                                    placeholder="اكتب هنا"  
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <button className='pricebutton' type="submit">إرسال</button>
                        </form>
                    </div>
                </div>
                <div className="container info text-center my-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="phone-contact">
                                <div className="phone-icon">
                                    <img src={phone} alt="Phone Icon" />
                                </div>
                                <div className="phone-info">
                                    <h2>الهاتف</h2>
                                    <p>0544923333</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="phone-contact">
                                <div className="phone-icon">
                                    <img src={mail} alt="Mail Icon" />
                                </div>
                                <div className="phone-info">
                                    <h2>البريد الإلكتروني</h2>
                                    <p>info@auun.net</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="phone-contact">
                                <div className="phone-icon">
                                    <img src={location} alt="Location Icon" />
                                </div>
                                <div className="phone-info">
                                    <h2>العنوان</h2>
                                    <p>جدة – حي البوادي</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="map-container map text-center my-5">
                    <iframe
                        className="map-iframe"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.708525779336!2d39.174734224055115!3d21.59729768019811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d14fbe8210a5%3A0x41f2535ef772ebd9!2z2LTYsdmD2Kkg2LnZiNmGINin2YTZhdiq2YXZitiy2Kkg2KfZhNil2K_Yp9ix2YrYqQ!5e0!3m2!1sar!2ssa!4v1724150660009!5m2!1sar!2ssa"
                        allowFullScreen=""
                        loading="lazy"
                        title="Saudi Arabia Map"
                    ></iframe>
                </div>
            </div>
        </>
    );
}

export default OfferPrice;
