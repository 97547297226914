import React from 'react';
import background from '../assest/background.png';
import overlay from '../assest/objects.png';
import vector from '../assest/profile avatar.png';
import phone from '../assest/3.png';
import mail from '../assest/2.png';
import location from '../assest/1.png';
import email from '../assest/Component 53.svg';
import whatsapp from '../assest/Component 54.svg';
import call from '../assest/Component 55.svg';
import ContactForm from '../ContactForm/ContactForm';
import './contact.css';

const Contact = () => {
    return (
        <>
    <div className="contact">
  
    <div className='ContactUs'>

            <div className='image-container'>
                <img src={background} alt="Background" />
                <div className='overlay'><img src={overlay} alt="Background" /></div> 
                <div className='titles'>
                    <h1>تواصل معنا</h1>
                    <p>تواصل معنا من خلال النموذج او عبر وسائل التواصل الظاهره نحن بخدمتكم</p>
                </div>
            </div>
        </div>
        <div className=" contact mt-5">
        <ContactForm/>
        </div>
        <div className="container text-center info mb-5">
        <div className="row">
          <div className="col-md-4">
          <div className="phone-contact">
        <div className="phone-icon">
        <img src={phone} alt="Phone Icon" />
      </div>
      <div className="phone-info">
        <h2>الهاتف</h2>
        <p>0544923333</p>
      </div>
     
    </div>
          </div>
          <div className="col-md-4">
          <div className="phone-contact">
        <div className="phone-icon">
        <img src={mail} alt="Phone Icon" />
      </div>
      <div className="phone-info">
        <h2>البريد الإلكتروني</h2>
        <p>info@auun.net</p>
      </div>
     
    </div>
          </div>
          <div className="col-md-4">
          <div className="phone-contact">
        <div className="phone-icon">
        <img src={location} alt="Phone Icon" />
      </div>
      <div className="phone-info">
        <h2>العنوان</h2>
        <p>جدة – حي البوادي</p>
      </div>
     
    </div>
          </div>
        </div>
        </div>
        <div className="map-container map text-center mb-5">
      <iframe
          className="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.708525779336!2d39.174734224055115!3d21.59729768019811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d14fbe8210a5%3A0x41f2535ef772ebd9!2z2LTYsdmD2Kkg2LnZiNmGINin2YTZhdiq2YXZitiy2Kkg2KfZhNil2K_Yp9ix2YrYqQ!5e0!3m2!1sar!2ssa!4v1724150660009!5m2!1sar!2ssa"
          allowFullScreen=""
          loading="lazy"
          title="Saudi Arabia Map"
      ></iframe>
  </div>
    </div>
    
        
             
            </>
    )
}

export default Contact;
